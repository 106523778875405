function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { hidePopup, showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { BUILD_A_BOX_BUTTON_EXCLUDED_PAGES } from 'swag-common/constants/main-swag.constants';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { isAvailableSuperSpeedSelector } from 'swag-client-common/redux/settings/settings.selectors';
import { isPreBuiltLimitedCartOpenedByCustomerSelector } from 'swag-client-common/redux/cart/cart.selectors';
import { isResellerInOrderCreationModeSelector } from 'swag-client-common/redux/user/user.selectors';
import { isResellerContractPendingSignWidgetShownSelector } from 'swag-client-common/redux/tenant-store/tenant-store.selectors';
import { urlReplacer } from 'swag-client-common/libs/url-modifier';
import { pathnameSelector, isPageFoundSelector, pathSelector } from '../../redux/common/common.selectors';
import { isSearchProductsOverlayShownSelector } from '../../redux/search-products/search-products.selectors';
import { isFiltersPopupShownSelector } from '../../redux/search-results/search-results.selectors';
import { categoriesAndFiltersAreHiddenForCBS, isCuratedBrandStoreSelector } from '../../redux/curated-brand-store/curated-brand-store-other.selector';
import { isBoxesPageSelector, isFilterOpenedSelector as isFilterOpenedBrowseProductsSelector } from '../../redux/product-listing/product-listing.selectors';
import { resetFilter } from '../../redux/product-listing/product-listing.actions';
import { loadAdditionalScriptsAction } from '../../../common/redux/tenant-store/tenant-store.actions.dynamic';
import { PageComponent } from './page';
const isFilterOpenedSelector = createSelector(pathnameSelector, isFiltersPopupShownSelector, isFilterOpenedBrowseProductsSelector, isBoxesPageSelector, (pathname, isFiltersPopupShown, isFilterOpened, isBoxesPage) => {
  const isSearchResultPage = pathname.includes(urlReplacer(APP_ROUTES.SEARCH_RESULTS));
  const isBrowseProductPage = pathname.includes(urlReplacer(APP_ROUTES.COLLECTIONS));
  if (isSearchResultPage) {
    return isFiltersPopupShown && !isBoxesPage;
  } else if (isBrowseProductPage) {
    return isFilterOpened && !isBoxesPage;
  } else {
    return false;
  }
});
const isBoxButtonShownSelector = createSelector(pathSelector, (state, props) => props.isBoxButtonShown, (path, isBoxButtonShown) => {
  if (BUILD_A_BOX_BUTTON_EXCLUDED_PAGES[path]) {
    return false;
  }
  return isBoxButtonShown;
});
const showSuperSpeedButtonSelector = createSelector(pathnameSelector, isFilterOpenedSelector, isAvailableSuperSpeedSelector, (pathname, isFilterOpened, isAvailableSuperSpeed) => {
  const isHomePage = pathname === APP_ROUTES.HOME;
  return !isHomePage && !isFilterOpened && isAvailableSuperSpeed;
});
const mapStateToProps = (state, props) => {
  return {
    showSearchResultsOverlay: isSearchProductsOverlayShownSelector(state),
    showLoadingOverlay: state.common.showLoadingOverlay,
    showCategories: props.showCategories,
    isPageBlocked: state.common.isPageBlocked,
    isPageFound: isPageFoundSelector(state),
    isBoxButtonShown: isBoxButtonShownSelector(state, props),
    isFilterOpened: isFilterOpenedSelector(state),
    isLimitedEdition: props.isLimitedEdition,
    isPreBuiltLimitedCart: isPreBuiltLimitedCartOpenedByCustomerSelector(state),
    showSuperSpeedButton: showSuperSpeedButtonSelector(state),
    isResellerInOrderCreationMode: isResellerInOrderCreationModeSelector(state),
    isResellerContractPendingSignWidgetShown: isResellerContractPendingSignWidgetShownSelector(state),
    isCuratedBrandStore: isCuratedBrandStoreSelector(),
    categoriesAndFiltersAreHiddenForCBS: categoriesAndFiltersAreHiddenForCBS(state)
  };
};
const mapDispatchToProps = dispatch => ({
  buildBoxClick: () => {
    dispatch(resetFilter({
      redirectAllSwag: true,
      resetCategory: true
    }));
    dispatch(hidePopup());
  },
  showPromoPagePopup: (popupName, params) => {
    dispatch(showPopup(_objectSpread({
      popup: popupName
    }, params)));
  },
  loadAdditionalScripts: () => dispatch(loadAdditionalScriptsAction())
});
export default connect(mapStateToProps, mapDispatchToProps)(PageComponent);