import { checkIsRestrictedCuratedBrandStore } from 'swag-common/utils/curated-brand-store.utils';
import { CuratedBrandStoreStatus } from 'swag-common/interfaces/tenant-store-settings';
import { selectResellerInCBSMode, selectResellerInCreateCBSMode } from 'swag-client-common/redux/user/user.selectors';
import { createSelector } from 'reselect';
import { CBS_HIDDEN_CATEGORIES_PRODUCT_COUNT } from 'swag-client-common/constants';
export const getCuratedBrandStore = () => {
  var _window$swag$storeSet;
  return (_window$swag$storeSet = window.swag.storeSettings) === null || _window$swag$storeSet === void 0 ? void 0 : _window$swag$storeSet.curatedBrandStore;
};
export const checkIsRestrictedCuratedBrandStoreSelector = () => {
  const cbs = getCuratedBrandStore();
  if (cbs !== null && cbs !== void 0 && cbs.restrictionLevel) {
    return checkIsRestrictedCuratedBrandStore(cbs.restrictionLevel);
  } else {
    return false;
  }
};
export const isCuratedBrandStoreSelector = () => {
  const cbs = getCuratedBrandStore();
  return !!(cbs !== null && cbs !== void 0 && cbs.parentId);
};
export const isCuratedBrandStoreInDraftSelector = () => {
  const cbs = getCuratedBrandStore();
  return (cbs === null || cbs === void 0 ? void 0 : cbs.status) === CuratedBrandStoreStatus.DRAFT;
};
export const isCuratedBrandStoreActiveSelector = () => {
  const cbs = getCuratedBrandStore();
  return (cbs === null || cbs === void 0 ? void 0 : cbs.status) === CuratedBrandStoreStatus.ACTIVE;
};
const cbsRootSelector = state => state.curatedBrandStore;
export const cbsProductsSelector = createSelector(cbsRootSelector, cbs => cbs === null || cbs === void 0 ? void 0 : cbs.products);
export const isLoadingSelector = createSelector(cbsRootSelector, cbs => cbs === null || cbs === void 0 ? void 0 : cbs.isLoading);
export const shouldShowCBSProductsWidgetSelector = createSelector(selectResellerInCBSMode, cbsProductsSelector, (isResellerCBSMode, cbsProducts) => isResellerCBSMode && Boolean(cbsProducts === null || cbsProducts === void 0 ? void 0 : cbsProducts.length));
export const shouldShowCreateCBSButtonSelector = createSelector(selectResellerInCreateCBSMode, cbsProductsSelector, (isResellerInCreateCBSMode, cbsProducts) => isResellerInCreateCBSMode && Boolean(cbsProducts === null || cbsProducts === void 0 ? void 0 : cbsProducts.length));
export const selectedCBSProductSelector = createSelector(cbsRootSelector, ({
  selectedProduct
}) => selectedProduct);
export const categoriesAndFiltersAreHiddenForCBS = createSelector(isCuratedBrandStoreSelector, state => state.productListing.totalInCategories, (isCuratedBrandStore, totalInCategories) => isCuratedBrandStore && totalInCategories <= CBS_HIDDEN_CATEGORIES_PRODUCT_COUNT);